import React from "react"
import { RichText } from "prismic-reactjs"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import PageHeaderSlim from '../components/pageHeaderSlim/pageHeaderSlim'
import PageBanner from '../components/pageBanner/pageBanner'
import SideTextSection from '../components/sideTextSection/sideTextSection'
import ImageSection from '../components/imageSection/imageSection'
  
export const query = graphql`
  query Articles($uid: String) {
    prismic {
      allNews_articless(uid: $uid) {
        edges {
          node {
            title
            thumbnail
            body {
              ... on PRISMIC_News_articlesBodyText_section {
                type
                label
                primary {
                  section_text
                  section_title
                }
              }
              ... on PRISMIC_News_articlesBodyImage {
                type
                label
                primary {
                  image
                }
              }
            }
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

const Article = ({ data }) => {
  const doc = data.prismic.allNews_articless.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title={RichText.asText(doc.node.title)} />
      <PageBanner background={doc.node.thumbnail} />
      <div style={{
        overflow: "auto"
      }}>
        <div className="section-slim-seamless">
          <div className="cols-wrapper">
            <div className="cols">
              <div className="col-8-md col-8-lg">
                <h1 className="t-h2 t-color-blue">
                  {RichText.asText(doc.node.title)}
                </h1>
              </div>
            </div>
          </div>
        </div>
        {doc.node.body !== null ? doc.node.body.map((section, index) => {
          switch (section.type) {
            case 'text_section':
              return (
                <SideTextSection section={section} key={index} />
              )
            case 'image':
              return (
                <ImageSection section={section} key={index} />
              )
            default:
              return null
          }
        }) : null}
      </div>
    </React.Fragment>
  )
}

export default Article
