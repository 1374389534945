import React, { useEffect } from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"
import VisibilitySensor from 'react-visibility-sensor'
import { connect } from 'react-redux'

import styles from './pageBanner.module.scss'
import { renderBackground } from '../../utils/renderBackground'
 
const PageHeader = ( props ) => {
  function onChange (isVisible) {    
    props.setHeaderTransparent(isVisible)
  }

  useEffect(() => {
    props.setHeaderTransparent(true)  

    return () => {
      props.setHeaderTransparent(false)   
    }
  })

  return (
     <VisibilitySensor onChange={onChange} scrollCheck={true} intervalCheck={false} partialVisibility={true} offset={{top: props.headerHeight + 1, bottom: 0}} scrollThrottle={50}>
      <div className={styles.header}>
        {props.background &&
          <div className={styles.background}>
            {renderBackground(props.background)}
          </div>
        }
      </div>
    </VisibilitySensor>
  )
}

PageHeader.propTypes = {
  background: PropTypes.object,
  headerHeight: PropTypes.number
}

PageHeader.defaultProps = {
  headerHeight: 0,
  background: null
}

const mapStateToProps = ({ headerHeight }) => {
  return { headerHeight }
}

const mapDispatchToProps = dispatch => {
  return {
    setHeaderTransparent: (val) => dispatch({ type: `SET_HEADERTRANSPARENT`, value: val })
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)